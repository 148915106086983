import { styled } from "@mui/material/styles"
import TableCell from "@mui/material/TableCell";

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  [`& .root`]: {
    padding: theme.spacing(1, 1, 2, 0),
    borderBottom: "none",
  },
  [`& .body`]: {
    color: theme.palette.common.black,
    borderBottom: "1px solid #9da0a0;",
  },
  fontWeight: 700,
}));

export default StyledHeaderCell;
