import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material";
import AppError from "@/AppError";
import ActionsBar from "@/components/ActionsBar";
import Backdrop from "@/components/Backdrop";
import BPNoResults from "@/components/BusinessPartner/NoResults";
import Alert from "@/components/Form/Alert";
import CustomControl from "@/components/Form/CustomControl";
import Loader from "@/components/Loader";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import { StyledHeading, StyledOrBox, StyledPageDescription, StyledPageFab, StyledSecondaryPageFab, StyledSubHeading } from "@/components/Styles/PagesComponentsCommon";
import {
  WARNING,
  canMarketCreateBPKey,
  canMarketCreateBPViaDunsKey,
} from "@/constants/constants";
import {
  businessPartnerRoute,
  createBusinessPartnerRoute,
} from "@/constants/routes";
import { AppContext } from "@/context/AppContext";
import { BusinessPartnersContext } from "@/context/BusinessPartnersContext";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DUNSTableHeader from "@/components/DUNS/DUNSTableHeader";
import { ResultsList } from "@/components/ESO/ResultsList";
import { EDIT } from "@/constants/constants";
import { DunsSearchType, TEXT } from "@/constants/forms";
import { handleApiError } from "@/utils/handleApiError";
import { useCreateDunsBPMutation, useCreateESOMutation, useSearchDUNSMutation } from "@/services/mutations";
import { useTranslation } from "react-i18next";
import { TableHeader } from "@/components/ESO/TableHeader";
import ESOCreateIcon from "@/assets/images/icons/ESO-Icon-UnFilled.svg?react";
export const EsoDunsSearchPage = () => {
  const navigate = useNavigate();
  const controller = new AbortController();
  const { control, handleSubmit, setValue } = useForm({});
  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
    userData: {
      [canMarketCreateBPKey]: canMarketCreateABP,
      [canMarketCreateBPViaDunsKey]: canMarketCreateBPViaDuns,
    },
  } = useContext(AppContext);

  const [showAlert, setShowAlert] = useState(false);

  const { nameSearch, citySearch, postcodeSearch, isEsoSearchEnabled, esoSearchColumns } =
    useContext(BusinessPartnersContext);
  const { t } = useTranslation();
  const stepNumber = !isEsoSearchEnabled ? "2. " : "3. ";
  const [dunsBusinessPartners, setDunsBusinessPartners] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [apiError, setApiError] = useState({});
  const location = useLocation();
  const { preFillFormData, searchType, dunsResponse, esoRecord } =
    location?.state || {};

  const searchESOQuery = useSearchDUNSMutation();
  const createBPFromDunsMutation = useCreateDunsBPMutation();
  const createESOMutation = useCreateESOMutation();

  const isESORecordPresent = Boolean(esoRecord?.gcid)

  useEffect(() => {
    setIsSearching(searchESOQuery.isPending);
    if (searchESOQuery.isError) {
      setApiError(handleApiError(searchESOQuery.error))
    }
    if (searchESOQuery.isSuccess) {
      setTotalRecords(searchESOQuery.data?.data?.result?.data.length);
      setDunsBusinessPartners(searchESOQuery.data?.data?.result?.data);
      setSearched(true);
    }
  }, [searchESOQuery.status]);

  useEffect(() => {
    setIsLoading(createBPFromDunsMutation.isPending);
    if (createBPFromDunsMutation.isError) {
      setApiError(handleApiError(createBPFromDunsMutation.error));
    }
    if (createBPFromDunsMutation.isSuccess) {
      if (createBPFromDunsMutation.data?.data?.result.created) {
        navigate(
          businessPartnerRoute +
          createBPFromDunsMutation.data?.data?.result?.createdId,
          {
            state: {
              createdFromDuns:
                createBPFromDunsMutation.data?.data?.result?.created,
            },
          },
        );
      } else {
        // If a business partner has been created already from D&B record, displays a warning.
        createAlert(
          WARNING,
          createBPFromDunsMutation.data?.data?.result?.responseMessage,
        );
      }
    }
  }, [createBPFromDunsMutation.status]);

  useEffect(() => {
    setIsLoading(createESOMutation.isPending);
    if (createESOMutation.isError) {
      setApiError(handleApiError(createESOMutation.error));
    }
    if (createESOMutation.isSuccess) {
      if (createESOMutation.data?.data?.result) {
        handleEsoCreateRecordResponse(createESOMutation.data?.data?.result);
      } else {
        // If a business partner has been created already from D&B record, displays a warning.
        createAlert(WARNING, createESOMutation.data?.data?.result?.responseMessage);
      }
    }
  }, [createESOMutation.status])

  const sendQuery = async ({
    companyName,
    city,
    postcode,
    vatNumber,
    companyNumber,
  }) => {
    searchESOQuery.mutate([
      companyName || "",
      city || "",
      postcode || "",
      companyNumber || "",
      vatNumber || "",
    ]);
  };

  const handlePreSearch = () => {
    // default searchtype to duns as fail safe.
    let preFillFormType =
      searchType === undefined ? DunsSearchType.LOOKUP : searchType;

    preFillFormType === DunsSearchType.LOOKUP
      ? preFillFormFields()
      : preFillCompanyNumbers();
  };

  const preFillCompanyNumbers = () => {
    if (dunsResponse) {
      //setCompanyCodesSearch(companyNumbers[0])
      setDunsBusinessPartners(dunsResponse?.data);
      setTotalRecords(dunsResponse.data?.length);
      setIsSearching(false);
      setSearched(true);
    }
  };

  // TODO: find a best way rather than explicit checking.
  const preFillFormFields = () => {
    if (preFillFormData === undefined) return;

    if (
      preFillFormData["name"] !== "" &&
      preFillFormData["name"] !== undefined
    ) {
      setValue("companyName", preFillFormData["name"]);
    }

    if (
      preFillFormData["city"] !== "" ||
      preFillFormData["city"] !== undefined
    ) {
      setValue("city", preFillFormData["city"]);
    }

    if (
      preFillFormData["postalCode"] !== "" ||
      preFillFormData["postalCode"] !== undefined
    ) {
      setValue("postcode", preFillFormData["postalCode"]);
    }

    if (
      preFillFormData["esoRecord"] !== "" ||
      preFillFormData["esoRecord"] !== undefined
    ) {
      setValue("vatNumber", esoRecord?.vatNumber);
    }
  };

  useEffect(() => {
    // prefill form data from router location state
    handlePreSearch();
  }, []);

  useEffect(() => {
    const handleSearchFormErrors = () => {
      if ((citySearch || postcodeSearch) && nameSearch) {
        setError(false);
      } else if ((citySearch || postcodeSearch) && !nameSearch) {
        setError(true);
      } else {
        setError(false);
      }
    };

    handleSearchFormErrors();
  }, [nameSearch, citySearch, postcodeSearch]);

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  const createBusinessPartnerFromDuns = async (item) => {
    const { companyNumber: dunsNumber } = item;
    const {
      gcid,
      ucid,
      addressUCID: addressUcid,
      vatNumber,
      nationalId1,
      nationalId2,
      nationalId3,
      address2ucid,
      billing_houseNo,
      billing_street,
      billing_city,
      billing_postalCode,
      billing_country,
    } = esoRecord || {};

    const payload = {
      gcid,
      ucid,
      addressUcid,
      vatNumber,
      nationalId1,
      nationalId2,
      nationalId3,
      dunsNumber,
      address2ucid,
      billing_houseNo,
      billing_street,
      billing_city,
      billing_postalCode,
      billing_country,
    };
    createBPFromDunsMutation.mutate(payload);
  };

  const createEsoFromSelectedRecord = async () => {
    let payload = { searchDuns: false, gcid: esoRecord?.gcid, countryCode: esoRecord?.countryCode }
    createESOMutation.mutate(payload)
  };

  const handleEsoCreateRecordResponse = (response) => {
    let route = "";
    let propData = {};
    // sucess == false && dunssearch == false
    if (!response?.success) {
      // simply show duns page with out prepopulation
      createAlert(WARNING, t("esoSearchDunsPage.warning"));
      return;
    }
    // sucess == true && dunssearch == false
    // open just created businesspartner
    else if (response?.success) {
      route = `${businessPartnerRoute}${response?.createdId}`;
    }

    // finally navigate
    navigate(route, {
      state: { ...propData },
    });
  };

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  return (
    <>
      <ActionsBar />

      <Box mt={3}>
        <StyledHeading component="h4" variant="h4">
          {`${stepNumber}${t("esoSearchDunsPage.dunheading")}`}
        </StyledHeading>
        <StyledSubHeading component="h5" variant="h5">
          {`${t("businessPartnersPage.step")} ${stepNumber} ${t("esoSearchDunsPage.subHeading")}`}
        </StyledSubHeading>
        <StyledPageDescription component="p" variant="body2">
          {t("esoSearchDunsPage.description")}
        </StyledPageDescription>
      </Box>

      <Box component="form" id="searchForm" onSubmit={handleSubmit(sendQuery)}>
        <div className="searchFormRow">
          <div className="col-md">
            <CustomControl
              control={control}
              label={t("esoSearchDunsPage.namelabel")}
              mode={EDIT}
              name="companyName"
              type={TEXT}
            />
            <CustomControl
              control={control}
              label={t("esoSearchDunsPage.cityLabel")}
              mode={EDIT}
              name="city"
              type={TEXT}
            />

            <CustomControl
              control={control}
              label={t("esoSearchDunsPage.postCodeLabel")}
              mode={EDIT}
              name="postcode"
              type={TEXT}
            />

            <CustomControl
              control={control}
              label={t("esoSearchDunsPage.vatLabel")}
              mode={EDIT}
              name="vatNumber"
              type={TEXT}
            />
          </div>

          <StyledOrBox
            display="flex"
            width={110}
            margin={1}
            borderRight="1px solid #8a8c8f"
            borderLeft="1px solid #8a8c8f"
            borderRadius={0}
          >
            <div className="fields-separator">{t("esoSearchDunsPage.Or")}</div>
          </StyledOrBox>
          <div className="col-md align-self-center">
            <div>
              <CustomControl
                control={control}
                label={t("esoSearchDunsPage.numberLabel")}
                mode={EDIT}
                name="companyNumber"
                type={TEXT}
              />
            </div>
          </div>
        </div>
        <Grid item xs={12} sm={12}>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button type="submit">
              <SearchIcon /> {t("esoSearchDunsPage.search")}
            </Button>
          </Box>
        </Grid>
      </Box>
      {isESORecordPresent && (
        <>
          <Box mt={3} display="flex" justifyContent="space-between">
            <StyledTitle component="h2" variant="h5">
              {`${t("esoSearchDunsPage.esoRecord")} - ${t("searchBPCommon.esoHelperMessage")}`}
            </StyledTitle>
          </Box>
          <StyledDivider />
          <ResultsList
            header={<TableHeader data={esoSearchColumns} />}
            data={[esoRecord.esoItem]}
            createEsoRecord={createEsoFromSelectedRecord}
            isDunsData={false}
          />
          <Box
            mt={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <StyledPageFab
              aria-label={t("searchCTAButtons.continueWithEso")}
              onClick={createEsoFromSelectedRecord}
            >
              <ESOCreateIcon /> {t("searchCTAButtons.continueWithEso")}
            </StyledPageFab>
          </Box>
        </>
      )}
      <Box mt={3} display="flex" flexDirection={"column"} gap={2}>
        {(searched || isLoading || dunsBusinessPartners?.length > 0) && (
          <StyledTitle component="h2" variant="h5">
            {`${t("esoSearchDunsPage.searchResults")} - ${t("searchBPCommon.searchDUNsResultselperMessage")}`}
          </StyledTitle>
        )}

        {dunsBusinessPartners.length > 0 && (
          <Typography
            variant="body2"
            style={{ lineHeight: "1.5", textAlign: "right" }}
            m="auto"
          >
            {t("esoSearchDunsPage.showing")} {totalRecords}{" "}
            {t("esoSearchDunsPage.results")}
          </Typography>
        )}
      </Box>

      {(searched || isLoading || dunsBusinessPartners?.length > 0) && <StyledDivider />}

      <StyledResultsContainer>
        {isSearching && <Loader />}

        {dunsBusinessPartners.length === 0 ? (
          <>
            {searched && (
              <BPNoResults message={t("searchBPNoResults.dunsMessage")} />
            )}
          </>
        ) : (
          <>
            <ResultsList
              header={<DUNSTableHeader />}
              data={dunsBusinessPartners}
              createEsoRecord={createBusinessPartnerFromDuns}
              canMarketCreateBP={canMarketCreateABP}
              canMarketCreateBPViaDuns={canMarketCreateBPViaDuns}
              isDunsData={true}
            />
          </>
        )}
      </StyledResultsContainer>
      {!isESORecordPresent && searched && canMarketCreateABP && (
        <Box mt={2} display="flex" justifyContent="center">
          {canMarketCreateABP && !isESORecordPresent && (
            <StyledSecondaryPageFab
              component={Link}
              disabled={!canMarketCreateABP}
              to={createBusinessPartnerRoute}
            >
              <AddIcon /> {t("searchCTAButtons.createBP")}
            </StyledSecondaryPageFab>
          )}
        </Box>
      )}
      {isLoading && <Backdrop />}

      {showAlert && alertStatus && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

const StyledResultsContainer = styled(Box)({
  position: "relative",
  minHeight: 150,
});
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));
const StyledDescription = styled(Typography)(({ theme }) => ({
  color: "#8a8c8f",
  marginTop: theme.spacing(0.5),
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: "#9da0a0",
  margin: theme.spacing(3, 0, 3, 0),
}));
