import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import "@/App.css";
import AuthError from "@/AuthError";
import AppMounted from "@/AppMounted";
import Backdrop from "@/components/Backdrop";

import apiAuthData from "@/services/apiAuth";
import apiUIData from "@/services/apiUI";

import {
  canMarketCreateBPKey,
  canMarketCreateContractKey,
  canMarketEditContractKey,
  canMarketEditBPKey,
  canMarketCreateContactKey,
  canMarketEditContactKey,
  idTokenExpiryKey,
  idTokenRealmKey,
  userNameKey,
  marketCodeKey,
  masterRetailerNumberKey,
  businessPartnerIdKey,
  dealerNameKey,
  userQnumberKey,
  canMarketCreateBPViaDunsKey,
  defaultDealerId,
  NKAM_ESO_Search,
  userKey,
  languageCode,
  ENGLISH_LANGUAGE,
  NKAM_Portal_BusinessPartner_Override_CoinResult,
  NKAM_ESOSearch_CountryList,
} from "@/constants/constants";

import utils from "@/utils/common";
import { handleApiError } from "@/utils/handleApiError";
import useToken from "@/utils/useToken";
import queryString from "query-string";
import { useQueries, useQuery } from "@tanstack/react-query";
import i18nConfig from "@/configuration/i18n"

function App() {
  const location = useLocation();

  const [authenticatedApp, setAuthenticatedApp] = useState(false);
  const [apiError, setApiError] = useState({});
  const [allDataSet, setAllDataSet] = useState();

  const onIdle = () => {
    // logout due to idle session
    if (utils.isTokenValid()) {
      let idToken = useToken.getIdToken();
      utils.redirectToSGateForLogout(idToken);
    } else {
      utils.redirectToSGateForLogin();
    }
  };

  const onAction = () => {
    if (localStorage.getItem(idTokenExpiryKey) && !utils.isTokenValid()) {
      utils.redirectToSGateForLogin();
    }
  };

  useIdleTimer({
    onIdle,
    onAction,
    timeout: 1000 * 60 * 20, // 20 minutes
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
  });

  let token = "";
  let accessToken = "";
  let tokenRealm = ""
  if (window.location.hash && !utils.isTokenValid()) {
    const parsedHash = queryString.parse(location.hash);
    accessToken = parsedHash.access_token;
    token = parsedHash.id_token;
    tokenRealm = parsedHash.state
  } else if (utils.isTokenValid()) {
    accessToken = useToken.getAccessToken();
    token = useToken.getIdToken();
    tokenRealm = utils.getFromLocalStorage(idTokenRealmKey);
  } else {
    utils.redirectToSGateForLogin();
  }

  const {
    data: tokenData,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ["tokenData"],
    queryFn: async () => {
      return apiAuthData.getTokenDecodeResponse(token, accessToken, tokenRealm);
    },
    enabled: Boolean(token) && Boolean(accessToken) && Boolean(tokenRealm) && !authenticatedApp,
  });

  const [
    countryOptionsQuery,
    dunsMarketConfigQuery,
    primaryLanguageOptionsQuery,
    esoSearchConfigQuery,
    coinResultStatusesQuery,
    typeOptionsQuery,
    esoCountryOptionsQuery
  ] = useQueries({
    queries: [
      {
        queryKey: ["countryOptionsQuery"],
        queryFn: async ({ signal }) => {
          return apiUIData.getCountryOptions(signal);
        },
        enabled: Boolean(token) && Boolean(accessToken) && authenticatedApp,
      },
      {
        queryKey: ["dunsMarketConfigQuery"],
        queryFn: async ({ signal }) => {
          return apiUIData.getDunsMarketConfig(signal);
        },
        enabled: Boolean(token) && Boolean(accessToken) && authenticatedApp,
      },
      {
        queryKey: ["getPrimaryLanguageOptions"],
        queryFn: async ({ signal }) => {
          return apiUIData.getPrimaryLanguageOptions(signal);
        },
        enabled: Boolean(token) && Boolean(accessToken) && authenticatedApp,
      },
      {
        queryKey: ["esoSearchConfigQuery"],
        queryFn: async ({ signal }) => {
          return apiUIData.getCrmConfiguration(NKAM_ESO_Search, signal);
        },
        enabled: Boolean(token) && Boolean(accessToken) && authenticatedApp,
      },
      {
        queryFn: async ({ signal }) => {
          return apiUIData.getCrmConfiguration(NKAM_Portal_BusinessPartner_Override_CoinResult, signal);
        },
        queryKey: ["coinResult"],
        enabled: Boolean(token) && Boolean(accessToken) && authenticatedApp,
      },
      {
        queryKey: ["typeOptionsQiery"],
        queryFn: async ({ signal }) => {
          return apiUIData.getTypeOptions(signal);
        },
        enabled: Boolean(token) && Boolean(accessToken) && authenticatedApp,
      },
      {
        queryKey: ["getESOCountryOptions"],
        queryFn: async ({ signal }) => {
          return apiUIData.getCrmConfiguration(NKAM_ESOSearch_CountryList, signal);
        },
        enabled: Boolean(token) && Boolean(accessToken) && authenticatedApp
      }
    ],
  });

  useEffect(() => {
    if (
      tokenData?.result && tokenData instanceof Error === false &&
      !authenticatedApp
    ) {
      const {
        tokenExpiry,
        userName,
        market,
        userQNumber,
        masterRetailerNumber,
        dealerName,
        businessPartnerId,
        canCreateBusinessPartner,
        canEditBusinessPartner,
        canCreateContract,
        canEditContract,
        canCreateContact,
        canEditContact,
        canCreateBusinessPartnerViaDuns,
        dealerGuid,
      } = tokenData.result;
      utils.secureSaveToLocalStorage(userNameKey, userName);
      utils.saveToLocalStorage(idTokenExpiryKey, tokenExpiry);
      utils.saveToLocalStorage(idTokenRealmKey, tokenRealm);
      useToken.saveAccessToken(accessToken);
      useToken.saveIdToken(token);

      let commonData = {
        [marketCodeKey]: market,
        [userQnumberKey]: userQNumber,
        [canMarketCreateBPViaDunsKey]: canCreateBusinessPartnerViaDuns,
        [canMarketCreateBPKey]: canCreateBusinessPartner,
        [canMarketEditBPKey]: canEditBusinessPartner,
        [canMarketCreateContractKey]: canCreateContract,
        [masterRetailerNumberKey]: masterRetailerNumber,
        [businessPartnerIdKey]: businessPartnerId,
        [dealerNameKey]: dealerName,
        [canMarketEditContractKey]: canEditContract,
        [canMarketCreateContactKey]: canCreateContact,
        [canMarketEditContactKey]: canEditContact,
        [defaultDealerId]: dealerGuid,
      };
      window.walkme = market;
      utils.secureSaveToLocalStorage(userKey, commonData);
      i18nConfig(market)
      setAuthenticatedApp(true);
    } else if (tokenData instanceof Error === true) {
      setApiError(handleApiError(tokenData))
    }
  }, [tokenData]);

  let isLoading = [countryOptionsQuery, dunsMarketConfigQuery, primaryLanguageOptionsQuery, esoSearchConfigQuery, coinResultStatusesQuery, typeOptionsQuery].some((query) => query.isLoading) || isFetching;


  useEffect(() => {
    if (
      countryOptionsQuery?.isSuccess &&
      dunsMarketConfigQuery?.isSuccess &&
      primaryLanguageOptionsQuery?.isSuccess &&
      esoSearchConfigQuery?.isSuccess &&
      coinResultStatusesQuery?.isSuccess &&
      typeOptionsQuery?.isSuccess &&
      esoCountryOptionsQuery?.isSuccess
    ) {
      if (primaryLanguageOptionsQuery?.data?.data?.result?.length > 0) {
        let secureData = utils.secureGetFromLocalStorage(userKey);
        let language = primaryLanguageOptionsQuery?.data?.data?.result?.find((item) => {
          return item.language !== ENGLISH_LANGUAGE;
        });
        secureData[languageCode] = language?.language;
        utils.secureSaveToLocalStorage(userKey, secureData);
      }
      setAllDataSet({
        country: countryOptionsQuery.data,
        isDunsMarket: dunsMarketConfigQuery.data,
        primaryLanguage: primaryLanguageOptionsQuery.data,
        isEsoEnabled: esoSearchConfigQuery?.data?.data?.result?.booleanValue,
        coinStatuses: coinResultStatusesQuery?.data?.data?.result?.stringValue,
        types: typeOptionsQuery.data,
        esoCountries: esoCountryOptionsQuery?.data?.data?.result
      });
    }
  }, [
    countryOptionsQuery.data,
    dunsMarketConfigQuery.data,
    primaryLanguageOptionsQuery.data,
    esoSearchConfigQuery.data,
    coinResultStatusesQuery?.data,
    typeOptionsQuery?.data,
    esoCountryOptionsQuery?.data
  ]);

  useEffect(() => {
    if (
      countryOptionsQuery?.isError ||
      dunsMarketConfigQuery?.isError ||
      primaryLanguageOptionsQuery?.isError ||
      esoSearchConfigQuery?.isError ||
      isError ||
      esoCountryOptionsQuery?.isError
    ) {
      setApiError(
        handleApiError(
          countryOptionsQuery?.error ||
          dunsMarketConfigQuery?.error ||
          primaryLanguageOptionsQuery?.error ||
          esoSearchConfigQuery?.error ||
          error ||
          esoCountryOptionsQuery?.error
        ),
      );
    }
  }, [
    countryOptionsQuery.error,
    dunsMarketConfigQuery.error,
    primaryLanguageOptionsQuery.error,
    esoSearchConfigQuery.error,
    error,
    esoCountryOptionsQuery.error
  ]);

  if (isLoading) {
    return <Backdrop />;
  }

  if (apiError?.isError) {
    const parsedHash = queryString.parse(location.hash);
    const idToken = parsedHash.id_token;

    return <AuthError idToken={idToken} apiError={apiError} />;
  }

  if (authenticatedApp) {
    return allDataSet ? <AppMounted appData={allDataSet} /> : <Backdrop />;
  }
  return null;
}

export default App;
