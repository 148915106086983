import React from "react";
import NoResultsIcon from "@/assets/images/icons/no-results.svg?react";
import { StyledNoResultsContainer, StyledNoResultsContents, StyledNoResultsDivider, StyledNoResultsPrompt } from "@/components/Styles/PagesComponentsCommon";

const BPNoResults = ({ message }) => {
  return (
    <StyledNoResultsContainer>
      <StyledNoResultsContents>
        <NoResultsIcon aria-label="no results" />
        <StyledNoResultsPrompt variant="body2" data-testid="noresult-message">
          {message}
        </StyledNoResultsPrompt>
        <StyledNoResultsDivider />
      </StyledNoResultsContents>
    </StyledNoResultsContainer>
  );
};

export default BPNoResults;
