import { createTheme } from "@mui/material/styles";
import BMWGroupTNProLight from "@/assets/fonts/BMWGroupTNPro-Light.woff2";
import BMWGroupTNPro from "@/assets/fonts/BMWGroupTNPro-Regular.woff2";
import BMWGroupTNProBold from "@/assets/fonts/BMWGroupTNPro-Bold.woff2";

const bmwGroupFontLight = {
  fontFamily: "BMWGroupTNPro",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "300",
  src: `
   local('BMWGroupTNPro'),
   local('BMWGroupTNPro-Light'),
   url(${BMWGroupTNProLight}) format('woff2')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const bmwGroupFont = {
  fontFamily: "BMWGroupTNPro",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "400",
  src: `
   local('BMWGroupTNPro'),
   local('BMWGroupTNPro-Regular'),
   url(${BMWGroupTNPro}) format('woff2')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const bmwGroupFontBold = {
  fontFamily: "BMWGroupTNPro",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "700",
  src: `
   local('BMWGroupTNPro'),
   local('BMWGroupTNPro-Bold'),
   srl(${BMWGroupTNProBold}) format('woff2')
 `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const bmwtheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: "#212121",
      white: "#ffffff",
      gray: "#dce0e4",
      dark: "#2f3030",
      orange: "#CF4A0D",
      orangeLight: "#cf4a0dd9",
    },
    primary: {
      light: "#e8f4fd",
      main: "#2f80ed",
      accent: "#0b406c", // Is this used? Needs replacing to be 'dark' variable and then deleting
      darkMain: "#02306f"
    },
    secondary: {
      main: "#f1f6fa", // Do we need this now we have it in background?
      light: "#60a0ff"
    },
    error: {
      light: "#f7e8ea",
      main: "#d32f2f",
    },
    info: {
      light: "#e8f4fd",
      main: "#2f80ed",
    },
    warning: {
      light: "#fdf4ed",
      main: "#f29a4b",
    },
    success: {
      light: "#e9f6ef",
      main: "#27ae60",
    },
    text: {
      primary: "#212121",
      secondary: "#434547",
      hint: "#676a6b",
    },
    divider: "#434547",
    background: {
      paper: "#ffffff",
      default: "#f1f6fa",
    },
    badges: {
      contracts: "#f07524",
      general: "#434547",
      other: "#2a336f",
      partner: "#145b71",
      teams: "#9f459a",
    },
  },
  shadows: [
    "none",
    "0 2px 10px 0 rgba(0, 0, 0, 0.25)",
    "inset 0 0 0 1px #dce0e4",
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: "BMWGroupTNPro, Arial, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    h1: {
      fontSize: 40,
      lineHeight: 1,
    },
    h2: {
      fontSize: 32,
      lineHeight: 1,
    },
    h3: {
      fontSize: 24,
      lineHeight: 1,
    },
    h4: {
      fontSize: 18,
      lineHeight: 1,
    },
    h5: {
      fontSize: 16,
      lineHeight: 1,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.4,
    },
    body2: {
      fontSize: 12,
      lineHeight: 1.25,
    },
    caption: {
      fontSize: 10,
      lineHeight: 1.2,
    },
    button: {
      fontSize: 14,
    },
  },
  spacing: 10,
  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  zIndex: {
    loader: 100,
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#d32f2f",
          display: "inline-block",
          scale: "1.6",

          "&.Mui-error": {
            color: "#d32f2f",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "none",
        fullWidth: true,
        margin: "dense",
        size: "small",
        variant: "outlined",
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        root: {
          alignItems: "center",
          borderRadius: 3,
          display: "flex",
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 30,
          paddingTop: 20,
        },
        filledError: {
          backgroundColor: "#f7e8ea",
          border: "1px solid #b02031",
          color: "#212121",
        },
        filledInfo: {
          backgroundColor: "#e8f4fd",
          border: "1px solid #2f80ed",
          color: "#212121",
        },
        filledWarning: {
          backgroundColor: "#fdf4ed",
          border: "1px solid #f29a4b",
          color: "#212121",
        },
        filledSuccess: {
          backgroundColor: "#e9f6ef",
          border: "1px solid #27ae60",
          color: "#212121",
        },
        icon: {
          fontSize: 20,
          marginRight: 10,
          opacity: 1,
          padding: 0,
        },
        message: {
          padding: 0,
        },
        action: {
          height: 20,
          alignItems: "center"
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: "primary",
        fullWidth: false,
        size: "large",
        variant: "contained",
      },
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
        contained: {
          borderRadius: 100,
          boxShadow: "0px 3px 10px 0px #00000020",

          "&$disabled": {
            backgroundColor: "#dce0e4",
            border: "1px solid rgba(121, 121, 121, 0.93)",
            color: "#797979",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#60a0ff",
          },
        },
        containedSecondary: {
          backgroundColor: "#2f3030",
          color: "#ffffff",

          "&:hover": {
            backgroundColor: "#434547",
          },
        },
        outlined: {
          borderRadius: 100,
          boxShadow: "0px 3px 10px 0px #00000020",

          "&.Mui-disabled": {
            backgroundColor: "#dce0e4",
            border: "1px solid rgba(121, 121, 121, 0.93)",
            color: "#797979",
          },
        },
        outlinedPrimary: {
          backgroundColor: "#f1f6fa",

          "&:hover": {
            backgroundColor: "#f1f6fa",
            borderWidth: 2,
          },
        },
        text: {
          borderRadius: 0,
        },
        textPrimary: {
          color: "#434547",

          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "inset 0 -2px 0 0 #2f80ed",
            color: "#2f80ed",

            "& svg path": {
              fill: "#2f80ed",
            },
          },
        },
        sizeLarge: {
          fontSize: 14,
          height: 40,
          padding: "5px 20px",
        },
      },
    },
    MuiFab: {
      defaultProps: {
        color: "primary",
        size: "large",
        variant: "extended",
      },
      styleOverrides: {
        root: {
          bottom: 20,
          position: "fixed",
          right: 60,
          zIndex: 150,
        },
        extended: {
          boxShadow: "0px 3px 10px 0px #00000020",
          textTransform: "initial",

          "&$sizeMedium": {
            borderRadius: 100,
            padding: "5px 20px",
          },
          "&.Mui-disabled": {
            backgroundColor: "#dce0e4",
            border: "1px solid rgba(121, 121, 121, 0.93)",
            color: "#797979",
          },
        },
        primary: {
          "&:hover": {
            backgroundColor: "#60a0ff",
          },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: { fontSize: "small" },
    },
    MuiCheckbox: {
      defaultProps: { color: "primary", size: "small" },
    },
    MuiSwitch: {
      defaultProps: { color: "primary", size: "small" },
      styleOverrides: {
        colorPrimary: {
          "&$checked": {
            "&.Mui-disabled": {
              color: "rgba(47, 128, 237, 0.5)",

              "& + .MuiSwitch-track": {
                backgroundColor: "#2f80ed",
              },
            },
          },
        },
      }
    },
    MuiPagination: {
      defaultProps: {
        color: "primary",
        shape: "rounded",
        variant: "outlined",
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: "body2",
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: "bottom-start",
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: "#434547",
          color: "#fff",
        },
        arrow: {
          "&::before": {
            backgroundColor: "#434547",
            border: "1px solid #434547",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": [bmwGroupFontLight, bmwGroupFont, bmwGroupFontBold],
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          colorPrimary: {
            backgroundColor: "#f1f6fa",
            color: "#333333",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&$disabled": {
            color: "rgba(0, 0, 0, 0.38)",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          right: 14,

          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperFullWidth: {
          maxWidth: 520,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        rounded: {
          backgroundColor: "#e7e9ea",
          borderColor: "#e7e9ea",
          color: "#333333",
          height: 30,
          minWidth: 30,

          "&$selected": {
            backgroundColor: "#2f80ed",
            borderColor: "#2f80ed",
            color: "#ffffff",
            pointerEvents: "none",
          },
          "&:hover": {
            backgroundColor: "#8fbcff",
            borderColor: "#8fbcff",
            color: "#ffffff",
          },
        },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        text: {
          backgroundColor: 'orange'
        }
      }
    },
    MuiDropzonePreviewList: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
        },
      },
    },
    MuiDropzoneSnackbar: {
      styleOverrides: {
        errorAlert: {
          backgroundColor: "#f7e8ea",
          border: "1px solid #b02031",
        },
        infoAlert: {
          backgroundColor: "#e8f4fd",
          border: "1px solid #2f80ed",
        },
        warningAlert: {
          backgroundColor: "#fdf4ed",
          border: "1px solid #f29a4b",
        },
        successAlert: {
          backgroundColor: "#e9f6ef",
          border: "1px solid #27ae60",
        },
        closeButton: {
          height: 20,
          width: 20,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          alignItems: "center",
          borderRadius: 3,
          color: "#212121",
          display: "flex",
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 30,
          paddingTop: 20,
        },
        message: {
          padding: 0,
        },
        action: {
          height: 20,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        filterFormValueInput: {
          "& .MuiTextField-root": {
            margin: "unset"
          },
          "& .MuiInputBase-input": {
            padding: "4px 0px 5px"
          }
        }
      }
    },
  },
});

export default bmwtheme;
